
import { get, patch, post } from "@/api/axios";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IEmployee, IExaGroup, IExaSku, IUser } from "@/state/models";
import EntityPropertiesEdit from "@/components/EntityPropertiesEdit.vue";
import DragAndDrop from "@/components/DragAndDrop.vue";
import Security from "@/components/Security.vue";
import TableRootEntities from "@/components/TableRootEntities.vue";
import UserSelector from "@/components/UserSelector.vue";
import SingleUserSelector from "@/components/SingleUserSelect.vue";

interface IExaTaskHistory {
  isCompleted: boolean;
  resultScore: null | number;
  totalScore: number;
  _id: string;
  userId: string;
  groupId: string;
}

@Component({
  components: {
    EntityPropertiesEdit,
    DragAndDrop,
    Security,
    TableRootEntities,
    UserSelector,
    SingleUserSelector,
  },
})
export default class ExaminationView extends Vue {
  public users_tab: string = "tab-selected";
  options = {};
  public foo = "";
  sku_headers: Array<any> = [
    {
      text: "Имя",
      align: "start",
      sortable: false,
      value: "name",
    },
    {
      text: "Тема",
      align: "start",
      value: "theme",
    },
  ];

  users_headers: Array<any> = [
    {
      text: "Имя",
      sortable: false,
      align: "start",
      value: "name",
    },
    {
      text: "Прогресс",
      value: "progress",
    },
    {
      text: "",
      align: "end",
      value: "actions",
    },
  ];

  public sku_selected: IExaSku[] = [];
  public emp_selected: IEmployee[] = [];

  public sku_all: IExaSku[] = [];
  public users_all: IEmployee[] = [];

  @Prop({ required: false, default: "" })
  public groupID!: string;

  public entity: IExaGroup | null = null;
  public selectedTeacher: IEmployee | null = null;
  public histories: (IExaTaskHistory | null)[] = [];

  public user_selected: IUser[] = [];

  is_loaded: boolean = false;

  created() {
    let id = this.$route.params.id ?? this.groupID;
    get(`/api/@examination/exa/group/${id}`).then((x) => {
      this.entity = x.data;
      get(`/api/@examination/exa/group/${this.entity!._id}/user/`).then(async (z) => {
        console.log(`/api/@examination/exa/group/${this.entity!._id}/user/`, z.data);
        this.emp_selected = z.data.map((w: any) => {
          return w.employee;
        });

        this.user_selected = z.data.map((w: any) => {
          return w.user;
        });

        await get(`/api/@examination/exa/group/${this.entity!._id}/teacher/`).then(
          (x) => {
            this.selectedTeacher = x.data;
            console.log("Selected Teacher ID: ", x.data);
          }
        );
        await get(`/api/@examination/exa/user/`).then((x) => {
          this.users_all = x.data;
        });

        await get(`/api/@examination/exa/sku`).then((x) => {
          this.sku_all = x.data;
        });

        await get(`/api/@examination/exa/group/${this.entity!._id}/progression/`).then(
          (x) => {
            console.log("Success fetch histories", x.data);
            for (const user of this.user_selected) {
              let history = (x.data as IExaTaskHistory[]).find(
                (x) => x.userId == user._id
              );
              if (history) this.histories.push(history);
              else this.histories.push(null);
            }
          }
        );

        this.sku_selected = [...this.sku_all.filter((x) => this.entity!.sku == x._id)];

        console.log(this.users_all);
        this.is_loaded = true;
      });
    });
  }

  @Watch("emp_selected")
  onUpdate() {
    let users = this.emp_selected;
    if (!this.is_loaded) return;
    console.log("onUpdate", users);
    console.log("this.entity", this.entity);
    post(`/api/@examination/exa/group/${this.entity!._id}/user/`, {
      employees: users.map((x) => x._id),
    }).then((x) => console.log("group save users", x.data));
  }

  openHistory(history: IExaTaskHistory) {
    console.log("OPEN HISTORY", history);
    this.$router.push({
      name: "exa_test_result",
      params: { id: history.groupId, userId: history.userId },
    });
  }

  openReportResult() {
    console.log("OPEN RESULT");
    this.$router.push({ name: "exa_test_report_result", params: { id: this.entity!._id } });
  }

  @Watch("sku_selected")
  onSkuUpdate() {
    if (!this.is_loaded) return;
    if (this.sku_selected.length == 0) return;
    let sku = this.sku_selected[0];
    console.log("onSkuUpdate", sku);
    console.log("this.entity", this.entity);

    patch(
      `/api/@examination/exa/group/${this.entity!._id}/sku/${sku?._id}`,
      {}
    ).then((x) => console.log("group save sku", x.data));
  }
}
